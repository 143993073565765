import React from "react";
import AppLayout from "./routers";

const App = () => {
  return (
    <>
      <AppLayout />
    </>
  );
};

export default App;
