import React, { useEffect, useRef, useState } from "react";
import MenuIcon from "../../assets/icons/menu.png";
import { useNavigate, useLocation } from "react-router-dom";
import { AccessTokenSlice } from "../../redux/reducers/reducer";
import { useDispatch, useSelector } from "react-redux";

export default function SettingBox() {
  const [showBox, setShowBox] = useState(false);
  const dispatch = useDispatch();
  const boxRef = useRef();
  const route = useNavigate();
  const location = useLocation();

  const adminId = useSelector(
    (state) => state?.auth?.login?.data?.data?.user?.id
  );

  const isPathname = (pathname) => {
    return location.pathname.includes(pathname);
  };

  const changeRoute = (path) => {
    route(path);
    setTimeout(() => setShowBox(false), 300);
  };

  const handleLogout = () => {
    dispatch(AccessTokenSlice.actions.removeToken());
  };

  useEffect(() => {
    function clickOutside(e) {
      if (boxRef.current && !boxRef.current.contains(e.target)) {
        setShowBox(false);
      }
    }
    document.addEventListener("click", clickOutside);
    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, []);
  return (
    <div className=" setting-box" ref={boxRef}>
      <div style={{ position: "relative" }}>
        <div className={`setting-popup ${showBox && "active-box"}`}>
          <div onClick={() => changeRoute(`/bookingRecord/${adminId}`)}>
            My Bookings
          </div>
          {!isPathname("/change-password") && (
            <div onClick={() => changeRoute("/change-password")}>
              Change Password
            </div>
          )}
          {isPathname("/change-password") && (
            <div onClick={() => changeRoute(-1)}>Go Back</div>
          )}
          <div onClick={handleLogout}>Logout</div>
        </div>
        <img
          src={MenuIcon}
          alt=""
          style={{ zIndex: 100, background: "white" }}
          onClick={() => {
            setShowBox(!showBox);
            setTimeout(() => setShowBox(false), 10000);
          }}
        />
      </div>
    </div>
  );
}
