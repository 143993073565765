import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import callApi from "../../services/api/apiClient";
import LoadingComponent from "../../components/loading/LoadingComponent";
import LocalIcon from "../../assets/icons";
import SettingBox from "../../components/Popup/SettingBox";

const MeetingRoomPage = () => {
  const navigate = useNavigate();
  const { facilityNo, facilityName } = useParams();

  const adminId = useSelector(
    (state) => state?.auth?.login?.data?.data?.user?.id
  );
  const roomsList = useSelector(
    (state) => state?.facilities?.facilityByCat?.data?.data
  );

  const handleBack = () => {
    navigate(`/homepage`);
  };

  useEffect(() => {
    callApi("facilities/facilityByCat")
      .withKeyParameter({ facilityName: facilityName })
      .loadingGroup("c")
      .executeDispatch();
  }, []);

  return (
    <div className="layout">
      <div className="container">
        <LoadingComponent loadingGroup={"c"}>
          <div className="meeting-page">
            <div className="meeting-head-box">
              <div className="meeting-title-con">
                <button className="back-con" onClick={handleBack}>
                  <img
                    src={LocalIcon.BackColor}
                    style={{ width: "11px" }}
                    title="back"
                    alt="back"
                  />
                </button>
                <div
                  className="record-title"
                  style={{ width: "calc(100% - 30px)", alignItems: "center" }}
                >
                  <h2>{facilityName} Page</h2>
                  <SettingBox />
                </div>
              </div>
            </div>

            <div className="meeting-layout">
              {roomsList?.map((room, index) => (
                <button
                  key={index}
                  className="room-btn-container"
                  onClick={() => navigate(`/${facilityName}/${room.id}`)}
                >
                  <div className="room-btm-img">
                    <img src={room.icon} alt={room.name} />
                  </div>
                  <div className="room-detail-con">
                    <h4>{room.name}</h4>
                    {
                      // room?.currentBooking ?
                      Object.keys(room?.currentBooking).length === 0 ? (
                        <p className="available">Availabel Now</p>
                      ) : (
                        <p className="occupied">
                          Occupied ({room?.currentBooking.startTime} to{" "}
                          {room?.currentBooking.endTime}){" "}
                        </p>
                      )
                    }
                    {room?.upcoming && (
                      <p>upcoming booking at {room?.upcoming}</p>
                    )}
                  </div>
                </button>
              ))}
            </div>
          </div>
        </LoadingComponent>
      </div>
    </div>
  );
};

export default MeetingRoomPage;
