import React, { useEffect, useRef, useState } from "react";
import { bookedRedx } from "../../helper/customSlice";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import LocalIcon from '../../assets/icons';

export const NewMapCreate = ({setAddAddress,setOpenMap,setOpenMapCreate,...props }) => {
    const mapCreateRef = useRef(null);
    const markersRef = useRef([]);
    const [locationName,setLocationName] = useState("")
    const [locationAddress,setLocationAddress] = useState("")
    const [latitude,setLatitude] = useState("")
    const [longitude,setLongitude] = useState("")

    useEffect(() => {
        const loadGoogleMapsScript = () => {
          if (!window.google) {
            const script = document.createElement("script");
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB7fbPdevYiD3SB3wmlvZ36ubTH3Y--qGE&libraries=places`;
            script.defer = true;
            script.async = true;
    
            script.onload = () => {
              if (window.google) {
                initMap();
              } else {
                console.error(
                  "Google Maps script loaded, but window.google is not defined."
                );
              }
            };
    
            document.head.appendChild(script);
          } else {
            initMap();
          }
        };
        let currentMarker= null
        const initMap = () => {
          const map = new window.google.maps.Map(mapCreateRef.current, {
            center: { lat: 16.8409, lng: 96.1735 },
            zoom: 15,
            mapTypeControl: false,
            streetViewControl: false,
            zoomControl: false,
          });
            
            map.addListener('click', function(event) {

              if (currentMarker) {
                currentMarker.setMap(null);
               }

              const newMarker = new window.google.maps.Marker({
                  position: event.latLng,
                  map: map,
                  title: 'New Marker',
                  icon: LocalIcon.Map
              });

              currentMarker = newMarker;
              // const lat = event.latLng.lat();
              // const lng = event.latLng.lng();
              setLatitude(event.latLng.lat())
              setLongitude(event.latLng.lng())
              
          });
        }
    
        loadGoogleMapsScript();
      }, []);

      const saveLocation = () => {
        const data = { name : locationName,address: locationAddress,latitude: latitude, longitude: longitude };
        setOpenMapCreate(false)
        setAddAddress(data);        
      };

    return(
        <Box className="map-box">
             <div className="date-modal-map">
                <input  className="addressInputCreate"
                        type="text"
                        placeholder="Location Name"
                        value={locationName}
                        onChange={(e) => setLocationName(e?.target.value)}/>
                <input  className="addressInputCreate"
                        style={{marginTop:'-10px'}}
                        type="text"
                        placeholder="Location Address"
                        value={locationAddress}
                        onChange={(e) => setLocationAddress(e?.target.value)}/>
                <div
                    id="mapContainer"
                    ref={mapCreateRef}
                    ></div>
                <div className="CreateButtonContainer">   
                  <div className="footer-right"
                       style={{ justifyContent: "end", width: "100%" }}>                 
                      <button className="cancel" onClick={() => setOpenMapCreate(false)}>
                          Cancel
                      </button>
                      <button className="save" onClick={() => saveLocation()}>
                          Save
                      </button>
                    </div>
                </div>
             </div>
        </Box>
    )
}