import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useAuth = () => {
  const auth = useSelector((state) => state?.AccessToken);
  // console.log(auth);
  //   const auth ="2|neYHHXZg2ks4JiPZ5TDaPAcvu1mJ7WIQIwQ3ErCHd192c9a3"

  return auth;
};

export default useAuth;
