import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import callApi from "../../services/api/apiClient";
import { useSelector, useDispatch } from "react-redux";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .required("New password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password must match")
    .required("Confirm password is required"),
});

const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  // const bookedList = useSelector((state)=> state?.booked?.bookedListByUser?.data)

  // useEffect(()=>{
  //   if(token){
  //     if(bookedList){
  //       navigate(`/bookingRecord/${adminId}`);
  //     }
  //     else{
  //       navigate("/homepage");
  //     }
  //   }
  // },[bookedList])

  const formik = useFormik({
    initialValues: {
      confirm_password: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      // setIsLoading(true);
      setTimeout(() => {
        callApi("auth/change_password")
          .withBody({
            password: values.password,
          })
          .loadingGroup("p")
          .executeDispatch()
          .then((res) => {
            if (res?.status) {
              actions.resetForm();
              navigate("/homepage");
            }
            actions.setSubmitting(false);
          });
        // setIsLoading(false);
      }, 100);
    },
  });

  return (
    <div className="layout">
      <div className="container">
        <form onSubmit={formik.handleSubmit} className="login-container">
          <h2 className="title">Change Password</h2>

          <input
            type="password"
            name="password"
            placeholder="New password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.password && formik.errors.password ? (
            <label className="error">{formik.errors.password}</label>
          ) : null}

          <input
            type="password"
            name="confirm_password"
            placeholder="Confirm password"
            value={formik.values.confirm_password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.confirm_password && formik.errors.confirm_password ? (
            <label className="error">{formik.errors.confirm_password}</label>
          ) : null}
          <button
            type="submit"
            className="login-btn"
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? "Changing in..." : "Change"}
          </button>
          {error && <p className="error">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordPage;
