import React,{useEffect, useState} from 'react'
import { Link, useParams, useNavigate} from 'react-router-dom';
import callApi from "../../services/api/apiClient";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';

// Style
import LocalIcon from '../../assets/icons/index';
import BookedRecord from '../../components/Booked/BookedRecord';

const formatDate = (date) =>{
    return dayjs(date).format('YYYY-MM-DD')
  }

const BookingRecords = () => {
  const navigate = useNavigate()
  let current_date = formatDate(new Date());

  const adminId = useSelector((state)=> state?.auth?.login?.data?.data?.user?.id)
  const bookedList = useSelector((state)=> state?.booked?.bookedListByUser?.data?.data)

  const currentBookedList = Array.isArray(bookedList) ? bookedList.filter((list) => list?.book_date === current_date) : [];
  const upcomingBookedList = Array.isArray(bookedList) ? bookedList.filter((list) => list?.book_date !== current_date) : [];
  const category = useSelector(
    (state) => state?.facilities?.category?.data?.data
  );

  useEffect(() => {
    callApi("booked/bookedListByUser")
        .withKeyParameter({UserId:adminId})
        .loadingGroup("user")
        .executeDispatch();
  },[]);

  return (
    <div className='layout'>
      <div className='container'>
        <LoadingComponent loadingGroup={"user"}>
          <div className='record-page'>
            <div className='record-title'>
              <h2 className='booking-record-title'>
                Booking Records
                {/* <div className='records-total'>{bookedList?.length}</div> */}
              </h2>
              {
                bookedList?.length > 0 &&
                  <button className="btn-icon" onClick={()=>navigate(`/homepage`)}>
                    <img src={LocalIcon.Home}/>
                  </button>
              }
            </div>
            
            {
              bookedList?.length > 0?
              <>
                <div className='record-con'>
                    <div className='booking-title' style={{backgroundColor:'#05445E'}}>
                        Today Bookings
                        <p>{currentBookedList?.length}</p>
                    </div>
                    {
                      currentBookedList?.length > 0 ?
                        <ul>
                          {
                            currentBookedList?.map((list,index)=>(
                              <li key={index}
                                  onClick={()=>navigate(`/${list?.facility_category?.name}/${list?.facility_id?.id}`)}>
                                <BookedRecord title={list?.title}
                                              icon ={list?.facility_category?.icon}
                                              needLocation={list?.facility_category?.needLocation}
                                              name={list?.facility_id?.name}
                                              book_date={list?.book_date}
                                              startTime = {list?.start_time}
                                              endTime = {list?.end_time}
                                              status={list?.status}
                                              />
                              </li>
                            ))
                          }
                      </ul>
                      :
                      <div className='empty-data'>You have no booking.</div>
                    }
                    
                </div>
                <div className='record-con'>
                    <div className='booking-title' style={{backgroundColor:'#189AB4'}}>
                        Upcoming Bookings
                        <p>{upcomingBookedList?.length}</p>
                    </div>
                    {
                      upcomingBookedList?.length > 0?
                        <ul>
                          {
                            upcomingBookedList?.map((list,index)=>(
                              <li key={index}
                              onClick={()=>navigate(`/${list?.facility_category?.name}/${list?.facility_id?.id}`)}>
                                <BookedRecord title={list?.title}
                                              icon ={list?.facility_category?.icon}
                                              needLocation={list?.facility_category?.needLocation}
                                              name={list?.facility_id?.name}
                                              book_date={list?.book_date}
                                              startTime = {list?.start_time}
                                              endTime = {list?.end_time}
                                              status={list?.status}
                                              />
                              </li>
                            ))
                          }
                        </ul>
                      :
                      <div className='empty-data'>You have no booking.</div>
                    }
                </div>          
              </>
              :
              <div className='empty-con'>
                <h3 className='empty-data'>You have no booking.</h3>
                <button className='save'
                        onClick={()=>navigate('/homepage')}>
                          Book Now
                </button>
              </div>
            }          
          </div>
        </LoadingComponent>
      </div>
    </div>
  )
}

export default BookingRecords;