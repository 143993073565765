import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import callApi from "../../services/api/apiClient";
// DatePicker
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import Select from "react-select";
import DateList from "../../components/Date/date";
import LoadingComponent from "../../components/loading/LoadingComponent";
import LocalIcon from "../../assets/icons";

const Booking = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { roomId, facilityName } = useParams();
  const [facilityImage, setFacilitImage] = useState();
  const [selectedDate, setSelectedDate] = useState(
    searchParams.get("date")
      ? dayjs(searchParams.get("date"))
      : dayjs(new Date())
  );
  const [selectedDay, setSelectedDay] = useState(false);
  const [holiday, setHoliday] = useState(false);
  const [holidayName, setHolidayName] = useState(null);
  let current_date = dayjs(new Date());
  let next_day = current_date.subtract(1, "day");
  let current_time = dayjs(selectedDate).format("HH");

  console.log(dayjs(searchParams.get("date")));

  // const adminId = useSelector((state)=> state?.auth?.login?.data?.data?.user?.id)
  // For Holiday
  const holidays = useSelector((state) => state?.date?.holidays?.data);

  // For room details(facility detail) by room id or fleet id
  const roomDetails = useSelector(
    (state) => state?.facilities?.facilityByRoomId?.data
  );

  useEffect(() => {
    callApi("date/holidays").executeDispatch();

    callApi("facilities/facilityByRoomId")
      .withKeyParameter({ facilityByRoomId: roomId })
      .loadingGroup("f")
      .executeDispatch();
  }, []);

  useEffect(() => {
    const categoryImage = roomDetails?.icon;
    setFacilitImage(categoryImage);
    const date = dayjs(selectedDate).format("dddd");
    const filter = roomDetails?.availableDays?.some((room) => room === date);
    setSelectedDay(filter);
  }, [roomDetails]);

  useEffect(() => {
    const scrollTime = dayjs(selectedDate).format("h A");
    const element = document.querySelector(`[data-time="${scrollTime}"]`);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: "smooth",
      });
    }
  }, []);

  useEffect(() => {
    // The selected date is less than the current date.
    const date = dayjs(selectedDate).format("dddd");
    const filter = roomDetails?.availableDays?.some((room) => room === date);
    setSelectedDay(filter);

    const day = formatDate(selectedDate);
    // This day is holiday.
    const filterHoliday = holidays?.some((holiday) => holiday.day === day);
    // Show holiday name.
    const filteredHolidays = holidays?.filter((holiday) => holiday.day === day);
    const holidayNames = filteredHolidays?.map((holiday) => holiday.name);

    setHoliday(filterHoliday);
    setHolidayName(holidayNames);

    callApi("booked/bookedListByDate")
      .withKeyParameter({ RoomId: roomId, bookedListByDate: day })
      .loadingGroup("date")
      .executeDispatch();
  }, [selectedDate]);

  const formatDate = (date) => {
    return dayjs(date).format("YYYY-MM-DD");
  };
  const handleBack = () => {
    navigate(`/${facilityName}`);
  };
  useEffect(() => {
    callApi("facilities/facilityByCat")
      .withKeyParameter({ facilityName: facilityName })
      .loadingGroup("c")
      .executeDispatch();
  }, []);

  return (
    <div className="layout">
      <div className="container">
        <LoadingComponent loadingGroup={"f"}>
          <div className="date-booking">
            <div className="date-container">
              <div className="date-select-con">
                <div
                  className="date-room-con"
                  style={{ backgroundImage: `url(${facilityImage})` }}
                >
                  <div className="date-title-con">
                    <button className="back-con" onClick={handleBack}>
                      <img
                        src={LocalIcon.Back}
                        style={{ width: "11px" }}
                        title="back"
                        alt="back"
                      />
                    </button>
                    <h3>{roomDetails?.name}</h3>
                    {/* <div className='record-title' style={{width:'calc(100% - 30px)'}}>
                        <h3>{roomDetails?.name}</h3>
                        <button className="save" onClick={()=>navigate(`/bookingRecord/${adminId}`)}>
                          Records
                        </button>
                      </div> */}
                  </div>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Choose Date"
                      value={selectedDate}
                      onChange={(newDate) => {
                        setSelectedDate(newDate);
                      }}
                    />
                    <input
                      type="hidden"
                      name="datePickerHidden"
                      value={formatDate(selectedDate.$d)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              {/* <LoadingComponent loadingGroup={"f"} loadingDesign="true"> */}
              <div className="date-detail">
                {holiday ? (
                  <label className="error">This day is "{holidayName}".</label>
                ) : !selectedDay ? (
                  <label className="error">Not available in that day.</label>
                ) : selectedDate < next_day ? (
                  <label className="error">
                    The selected date is less than the current date.
                  </label>
                ) : (
                  <label>{dayjs(selectedDate).format("dddd, MMMM D")}</label>
                )}
              </div>
              {/* </LoadingComponent> */}
            </div>
            <LoadingComponent loadingGroup={"date"} loadingDesign="true">
              {(!selectedDay ||
                holiday ||
                selectedDate < next_day ||
                formatDate(selectedDate) === formatDate(current_date)) && (
                <div
                  className="unavailabel-date"
                  style={{
                    height: !selectedDay
                      ? "81.2%"
                      : formatDate(selectedDate) === formatDate(current_date)
                      ? 52 * (Number(current_time) + 1)
                      : "81.2%",
                  }}
                ></div>
              )}

              <div
                className={
                  !selectedDay || holiday || selectedDate < next_day
                    ? "date-list-container unavailable"
                    : "date-list-container"
                }
              >
                <DateList
                  changeRoom={roomId}
                  changeDate={formatDate(selectedDate.$d)}
                  selectedDay={selectedDay}
                  current_date={formatDate(current_date)}
                  current_time={Number(current_time)}
                />
              </div>
            </LoadingComponent>
          </div>
        </LoadingComponent>
      </div>
    </div>
  );
};

export default Booking;
